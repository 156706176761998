var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-tabs',{attrs:{"show-arrows":"","next-icon":"mdi-arrow-right-bold-box-outline","prev-icon":"mdi-arrow-left-bold-box-outline"},model:{value:(_vm.local.dia),callback:function ($$v) {_vm.$set(_vm.local, "dia", $$v)},expression:"local.dia"}},[_vm._l((Math.max(parseInt(_vm.local.qtApresentacoes), 1)),function(ndia){return _c('v-tab',{key:ndia,staticClass:"diaItem"},[_vm._v(" dia "+_vm._s(ndia)+" ")])}),_c('v-sheet',{staticClass:"d-inline-flex align-content-center",style:({
      backgroundColor: _vm.sheet.title.background,
      color: _vm.sheet.title.color
    }),attrs:{"outlined":"","elevation":3,"dense":""}},[(_vm.local.qtApresentacoes > 1)?[_c('div',{style:({
          borderRight: `1px solid ${_vm.sheet.title.color}`
        })},[_c('v-btn',{attrs:{"plain":"","icon":"","color":_vm.sheet.title.color},on:{"click":function($event){return _vm.removerDias()}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)],1)]:_vm._e(),(_vm.local.qtApresentacoes < 8)?[_c('div',[_c('v-btn',{attrs:{"plain":"","icon":"","color":_vm.sheet.title.color},on:{"click":function($event){return _vm.adicionarDias()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]:_vm._e()],2),(_vm.local)?[_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.local.dia),callback:function ($$v) {_vm.$set(_vm.local, "dia", $$v)},expression:"local.dia"}},_vm._l((Math.max(parseInt(_vm.local.qtApresentacoes), 1)),function(ndia){return _c('v-tab-item',{key:ndia},[_c('v-sheet',{staticClass:"tableContainer",attrs:{"fixed":""}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{style:({
                borderRadius: 0,
                textAlign: 'center',
                backgroundColor: _vm.sheet.title.background,
                color: _vm.sheet.title.color
              })},[_c('h3',{style:({
                  width: '100%',
                  fontSize: '1.1rem',
                  textTransform: 'uppercase'
                })},[_vm._v(" Dia "+_vm._s(_vm.local.dia + 1)+" ")])]),_c('daily-expenses-table',{attrs:{"local":_vm.local}})],1)],1)],1)}),1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }