<template>
  <section>
    <v-card tile elevation="5" outlined>
      <v-card-title class="pb-6">
        Dados principais
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <h-text-input
              v-model="project.titulo"
              v-bind:maxlength="50"
              v-bind:counter="50"
              tip="Informe um nome para o seu projeto. O nome pode conter até 50 caracteres e será utilizado tanto para ser referenciado na sua lista de projetos quanto para ser o título do certificado."
              placeholder="Informe o nome do projeto"
              label="Nome do projeto"
            />
          </v-col>
          <v-col cols="12" md="6" sm="12">
            <h-text-input
              v-model="project.proponente"
              :maxlength="50"
              :counter="50"
              tip="O nome do principal proponente ou da equipe responsável pela elaboração do projeto. "
              placeholder="Informe o nome do proponente"
              label="Nome do proponente"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" sm="12">
            <h-text-area
              v-model="project.resumo"
              :maxlength="250"
              :counter="250"
              tip="Resuma em até 250 caracteres, os principais pontos relevantes do seu projeto."
              placeholder="Breve resumo sobre o projeto (opcional)"
              label="Resumo do projeto"
            ></h-text-area>
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-select
              required
              v-model="project.segmento"
              :items="segmentos"
              item-text="label"
              item-value="id"
              label="Segmento"
              return-object
              single-line
              outlined
              placeholder="Selecione um segmento"
            >
            </v-select>
          </v-col>
          <v-col cols="12" md="3" sm="12" style="position:relative">
            <v-text-field
              tabindex="0"
              outlined
              label="Ano do Projeto"
              :maxlength="4"
              :rules="[rules_ano.preenchido, rules_ano.valido]"
              v-model="project.ano"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined elevation="5" class="mt-6">
      <v-card-title class="pb-6">
        Fontes de Recursos Financeiros
      </v-card-title>
      <v-card-text class="px-4 pb-8">
        <v-row>
          <v-col cols="12" md="3" sm="12">
            <v-row v-for="fonte in project.fontesRecurso" v-bind:key="fonte.code">
              <v-col class="relative text-right">
                <label :for="fonte.label">{{ fonte.label }}</label>
              </v-col>
              <v-col class="relative">
                <h-money-input
                  tabindex="0"
                  outlined
                  v-bind:options="{
                    locale: 'pt-BR',
                    length: 12,
                    precision: 2,
                    empty: null,
                  }"
                  v-bind:properties="{
                    prefix: '',
                    readonly: false,
                    singleLine: true,
                    hideDetails: true,
                    dense: true,
                    //label: fonte.label,
                    disabled: false,
                    outlined: false,
                    clearable: false,
                    placeholder: ' ',
                  }"
                  v-bind:tip="fonte.hint"
                  v-model="fonte.value"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-col class="totalFontesRecurso pt-6">
                  <label>Total R$</label>
                </v-col>
                <v-col class="relative totalFonteRecurso pt-6">
                  <h-money-input
                    v-model="valorTotalFonteRecurso"
                    v-bind:options="{
                      locale: 'pt-BR',
                      length: 12,
                      precision: 2,
                      empty: null,
                    }"
                    v-bind:properties="{
                      prefix: 'R$',
                      readonly: true,
                      singleLine: true,
                      hideDetails: true,
                      dense: true,
                      disabled: false,
                      outlined: false,
                      clearable: false,
                      placeholder: ' ',
                    }"
                    v-bind:readonly="true"
                    tip="Valor total das fontes de recurso (GOP)"
                    v-bind:label="'Total'"
                  />
                </v-col>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="8">
            <project-funding-chart
              :project="project"
              v-if="showChart"
              style="width: 100%; min-width: 100%; border: 1px solid #e5e5e5;"
              class="mt-n8"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card outlined v-if="$vuetify.breakpoint.smAndDown" elevation="5" class="mt-6">
      <v-card-title class="pb-4">
        Gráfico
      </v-card-title>
      <v-card-text>
        <project-funding-chart :project="project" v-if="showChart" style="width: 100%; min-width: 100%;" />
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
import HTextInput from "../custom/HTextInput";
import HTextArea from "../custom/HTextArea";
import HMoneyInput from "../custom/HMoneyInput";
import ProjectFundingChart from "./ProjectFundingChart";

export default {
  components: {
    HTextInput,
    HTextArea,
    HMoneyInput,
    ProjectFundingChart,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showChart: false,
    rules_ano: {
      valido: (value) => parseInt(value) > 1900 || "Ano do projeto invalido",
      preenchido: (value) => !!value || "Ano deve ser informado",
    },
    segmentos: [
      {
        id: 100,
        label: "Artes Cênicas",
        f: 1.6,
      },
      {
        id: 110,
        label: "Artes visuais",
        f: 1.58,
      },
      {
        id: 120,
        label: "Audiovisual",
        f: 1.61,
      },
      {
        id: 130,
        label: "Humanidades (setor editorial)",
        f: 1.69,
      },
      {
        id: 140,
        label: "Patrimônio Cultural",
        f: 1.51,
      },
      {
        id: 150,
        label: "Música",
        f: 1.64,
      },
      {
        id: 160,
        label: "Cultura Popular",
        f: 1.51,
      },
    ],
    fontesRecurso: [
      {
        code: "FRPUB",
        label: "Recursos Públicos",
        hint: "Valor total originado de editais, leis de incentivo e fundos públicos",
      },
      {
        code: "FRPRV",
        label: "Recursos Privados",
        hint: "Valor total de patrocínio não incentivado, tais como doações e apoios financeiros de empresas privadas",
      },
      {
        code: "FRRP",
        label: "Recursos Próprios",
      },
    ],
  }),
  computed: {
    valorTotalFonteRecurso() {
      // return 1000
      return (
        (this.project.fontesRecurso && this.project.fontesRecurso.reduce((acc, item) => acc + ((item.value && parseFloat(item.value, 0)) || 0), 0)) ||
        0
      );
    },
  },
  mounted() {
    // @NOTE: Tem the dar um tempo pro e-charts, ele é mais lerdinho
    this.showChart = true;
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 640px) and (orientation: portrait) {
  .v-card__title {
    font-size: 1rem;
  }
}
</style>
