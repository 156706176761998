<template>
  <v-tabs
    v-model="local.dia"
    show-arrows
    next-icon="mdi-arrow-right-bold-box-outline"
    prev-icon="mdi-arrow-left-bold-box-outline"
  >
    <v-tab
      class="diaItem"
      v-for="ndia in Math.max(parseInt(local.qtApresentacoes), 1)"
      v-bind:key="ndia"
    >
      dia {{ ndia }}
    </v-tab>
    <v-sheet
      outlined
      :elevation="3"
      dense
      class="d-inline-flex align-content-center"
      :style="{
        backgroundColor: sheet.title.background,
        color: sheet.title.color
      }"
    >
      <template v-if="local.qtApresentacoes > 1">
        <div
          :style="{
            borderRight: `1px solid ${sheet.title.color}`
          }"
        >
          <v-btn plain icon :color="sheet.title.color" @click="removerDias()">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-if="local.qtApresentacoes < 8">
        <div>
          <v-btn plain icon :color="sheet.title.color" @click="adicionarDias()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>
      </template>
    </v-sheet>

    <template v-if="local">
      <v-tabs-items v-model="local.dia" touchless>
        <v-tab-item
          v-for="ndia in Math.max(parseInt(local.qtApresentacoes), 1)"
          v-bind:key="ndia"
        >
          <v-sheet fixed class="tableContainer">
            <v-card elevation="0">
              <v-card-title
                :style="{
                  borderRadius: 0,
                  textAlign: 'center',
                  backgroundColor: sheet.title.background,
                  color: sheet.title.color
                }"
              >
                <h3
                  :style="{
                    width: '100%',
                    fontSize: '1.1rem',
                    textTransform: 'uppercase'
                  }"
                >
                  Dia {{ local.dia + 1 }}
                </h3>
              </v-card-title>
              <daily-expenses-table :local="local" />
            </v-card>
          </v-sheet>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </v-tabs>
</template>

<script>
import DailyExpensesTable from "./DailyExpensesTable";

export default {
  components: {
    DailyExpensesTable
  },
  props: {
    local: {
      type: Object,
      required: true
    },
    sheet: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    emptyExpense: {
      local: {
        publico: 0,
        despesaTransporteChegada: 0,
        despesatransporteRedondezas: 0,
        despesaHospedagem: 0,
        despesaAlimentos: 0,
        despesaOutros: 0
      },
      excursionista: {
        publico: 0,
        despesaTransporteChegada: 0,
        despesatransporteRedondezas: 0,
        despesaHospedagem: 0,
        despesaAlimentos: 0,
        despesaOutros: 0
      },
      nacional: {
        publico: 0,
        despesaTransporteChegada: 0,
        despesatransporteRedondezas: 0,
        despesaHospedagem: 0,
        despesaAlimentos: 0,
        despesaOutros: 0
      },
      internacional: {
        publico: 0,
        despesaTransporteChegada: 0,
        despesatransporteRedondezas: 0,
        despesaHospedagem: 0,
        despesaAlimentos: 0,
        despesaOutros: 0
      }
    }
  }),
  methods: {
    adicionarDias() {
      // eslint-disable-next-line no-debugger
      this.local.qtApresentacoes++;
      this.local.dia = this.local.qtApresentacoes - 1;
      this.local.despesas = [...(this.local.despesas ?? []), this.emptyExpense];
    },
    removerDias() {
      if (this.local.dia < this.local.qtApresentacoes - 1) this.local.dia--;
      this.local.qtApresentacoes--;
      const [, ...expenses] = this.local.despesas.reverse();
      this.local.despesas = expenses.reverse();
    }
  }
};
</script>
