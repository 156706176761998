<template>
  <section>
    <confirm ref="confirm"></confirm>
    <template v-if="project.locais.length > 0">
      <v-sheet flat outlined v-bind:key="idx" v-for="(local, idx) in project.locais" class="pa-3">
        <v-card>
          <v-card-title
            :style="{
              backgroundColor: sheetColors[idx % sheetColors.length].background,
              color: sheetColors[idx % sheetColors.length].color,
            }"
          >
            LOCAL {{ idx + 1 }}
            <v-spacer />
            <v-btn icon right small color="white" v-if="idx > 0" @click="removeLocal(idx)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pa-6">
            <v-row>
              <v-col cols="12" md="5" sm="12">
                <h-text-input
                  label="Nome do estabelecimento"
                  required
                  dense
                  tip="Informe o nome do estabelecimento"
                  v-model="local.estabelecimento"
                />
              </v-col>
              <v-col cols="12" md="3" sm="12">
                <h-text-input class="" dense label="Cidade" tip="Informe o nome da cidade onde o estabelecimento se situa" v-model="local.cidade" />
              </v-col>
              <v-col cols="12" md="2" sm="12">
                <h-text-input dense v-bind:maxlength="2" label="Estado" tip="Informe o estado (ex: SP, RJ, AL, AM, etc)" v-model="local.estado" />
              </v-col>
              <v-col cols="12" md="2" sm="12" class="relative">
                <h-money-input
                  v-bind:options="{
                    precision: 0,
                    length: 7,
                    outputMask: 'Integer',
                    locale: 'pt-BR',
                  }"
                  type="number"
                  label="Lotação (pessoas)"
                  tip="Informe a lotação máxima aproximada do estabelecimento"
                  v-bind:outlined="true"
                  v-model="local.lotacao"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <daily-expenses-estimate-form :local="local" :sheet="sheetColors[idx % sheetColors.length]" />
          </v-card-actions>
        </v-card>
      </v-sheet>
      <v-sheet>
        <v-row>
          <v-spacer />
          <v-btn class="ma-6" color="primary" :large="$vuetify.breakpoint.smAndDown" @click="addNovoLocal()">
            Adicionar outro local
            <v-icon dark>
              mdi-plus
            </v-icon>
          </v-btn>
          <v-spacer v-if="$vuetify.breakpoint.smAndDown" />
        </v-row>
      </v-sheet>
    </template>
  </section>
</template>

<script>
import HTextInput from "../custom/HTextInput";
import HMoneyInput from "../custom/HMoneyInput";
import Confirm from "../../components/custom/HConfirm";
import DailyExpensesEstimateForm from "./DailyExpensesEstimateForm";

export default {
  components: {
    Confirm,
    HTextInput,
    HMoneyInput,
    DailyExpensesEstimateForm,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dirty: false,
    sheetState: undefined,
    sheetColors: [
      {
        color: "#573688",
        background: "#f0c647",
        title: {
          color: "#573688",
          background: "#f0c647",
        },
      },
      {
        color: "white",
        background: "#573688",
        title: {
          color: "white",
          background: "#573688",
        },
      },
      {
        color: "white",
        background: "#e53d31",
        title: {
          color: "white",
          background: "#e53d31",
        },
      },
      {
        color: "white",
        background: "blue",
        title: {
          color: "black",
          background: "#91c0ed",
        },
      },
      {
        color: "white",
        background: "#ae7918",
        title: {
          color: "white",
          background: "#c29336",
        },
      },
      {
        color: "white",
        background: "#359e0d",
        title: {
          color: "white",
          background: "#66cc66",
        },
      },
    ],
  }),
  methods: {
    addNovoLocal() {
      this.project.locais.push({
        dia: 0,
        estabelecimento: "",
        estado: "",
        cidade: "",
        lotacao: 0,
        qtApresentacoes: 1,
        despesas: [
          {
            local: {
              publico: 0,
              despesaTransporteChegada: 0,
              despesatransporteRedondezas: 0,
              despesaHospedagem: 0,
              despesaAlimentos: 0,
              despesaOutros: 0,
            },
            excursionista: {
              publico: 0,
              despesaTransporteChegada: 0,
              despesatransporteRedondezas: 0,
              despesaHospedagem: 0,
              despesaAlimentos: 0,
              despesaOutros: 0,
            },
            nacional: {
              publico: 0,
              despesaTransporteChegada: 0,
              despesatransporteRedondezas: 0,
              despesaHospedagem: 0,
              despesaAlimentos: 0,
              despesaOutros: 0,
            },
            internacional: {
              publico: 0,
              despesaTransporteChegada: 0,
              despesatransporteRedondezas: 0,
              despesaHospedagem: 0,
              despesaAlimentos: 0,
              despesaOutros: 0,
            },
          },
        ],
      });
      // this.$emit("update:project", project);
    },
    removeLocal(idx) {
      this.$refs.confirm
        .open("Excluir local", "Tem certeza?", {
          color: "red",
        })
        .then((confirmado) => {
          if (confirmado) {
            this.project.locais.splice(idx, 1);
          }
        });
    },
  },
};
</script>
