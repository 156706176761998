<template>
  <v-chart
    class="chart"
    :option="dadosGraficoFonteRecurso"
    style="min-height: 300px; min-width: 100%"
  />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";

import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

const clearValue = value => {
  if (typeof value == "string") {
    value = "0" + value.replace(/[^0-9]/g, "");
  }
  value = Math.trunc(parseFloat(value)).toFixed(0);
  return value.replace(/[^0-9]/g, "");
};

export default {
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    fontesRecurso: [
      {
        code: "FRPUB",
        label: "Recursos Públicos",
        hint:
          "Valor total originado de editais, leis de incentivo e fundos públicos"
      },
      {
        code: "FRPRV",
        label: "Recursos Privados",
        hint:
          "Valor total de patrocínio não incentivado, tais como doações e apoios financeiros de empresas privadas"
      },
      {
        code: "FRRP",
        label: "Recursos Próprios"
      }
    ]
  }),
  computed: {
    dadosSerieFonteRecurso() {
      return (
        (this.project.fontesRecurso &&
          this.project.fontesRecurso.map(e => {
            var label = this.fontesRecurso.find(o => o.code == e.code).label;
            return {
              name: label, // + compl,
              value: clearValue(e.value) - 0
            };
          })) ||
        []
      );
    },
    dadosGraficoFonteRecurso() {
      return {
        legend: {
          orient: "vertical",
          left: this.$vuetify.breakpoint.smAndDown ? "left" : "right",
          top: "bottom",
          data: this.fontesRecurso.map(e => e.label)
        },
        autoresize: true,
        title: {
          show: false
        },
        tooltip: {
          trigger: "item",
          formatter: "<b>{b}</b>{c}<br/>({d}%)"
        },
        series: {
          type: "pie",
          data: this.dadosSerieFonteRecurso,
          center: ["50%", this.$vuetify.breakpoint.smAndDown ? "30%" : "50%"],
          radius: ["25%", "60%"],
          itemStyle: {
            borderRadius: 7,
            borderWidth: 2
          },
          label: {
            show: true
          },
          color: ["#f0c647", "#573688", "#e53d31"],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      };
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  min-width: 100% !important;
}
</style>
