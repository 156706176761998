<template>
  <section>
    <div>
      <table style="width:100%">
        <thead>
          <tr valign="bottom">
            <th rowspan="3"></th>
            <th rowspan="3" style="border-left:none">
              Público
            </th>
            <th colspan="7" style="border-left:none;">
              Estimativa de despesa diária por pessoa
            </th>
          </tr>
          <tr>
            <th colspan="2">
              Despesas com Transporte R$
            </th>
            <th rowspan="2">
              Hospedagem
            </th>
            <th rowspan="2">
              Alimentos e<br />
              Bebidas
            </th>
            <th rowspan="2">
              Outras
            </th>
            <th rowspan="2" class="totalColumn">
              Total por pessoa
            </th>
            <th rowspan="2" class="totalColumn">
              Total
            </th>
          </tr>
          <tr>
            <th>Intermunicipal</th>
            <th>Intramunicipal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tipo in tipoPublico" v-bind:key="tipo.label">
            <template
              v-if="
                local.despesas[local.dia] && local.despesas[local.dia][tipo.key]
              "
            >
              <td>
                <div class="tipoPublico">
                  {{ tipo.label }}
                </div>
              </td>
              <td valign="middle">
                <h-money-input
                  v-bind:properties="formatoColunasPlanilha"
                  v-bind:autocomplete="'new'"
                  class="right-input"
                  align="right"
                  v-bind:altlabel="'Público estimado'"
                  v-bind:tip="'Informe o público estimado de ' + tipo.tip"
                  v-bind:options="{
                    precision: 0,
                    length: 7,
                    locale: 'pt-BR'
                  }"
                  v-model="local.despesas[local.dia][tipo.key].publico"
                />
              </td>
              <td>
                <h-money-input
                  class="right-input"
                  v-bind:properties="formatoColunasPlanilha"
                  v-bind:autocomplete="'new'"
                  v-bind:altlabel="'Despesa com transporte intermunicipal'"
                  v-bind:tip="
                    'Informe o valor aproximado gasto com transporte entre cidades por ' +
                      tipo.tip
                  "
                  v-bind:options="{
                    outputMask: '################',
                    length: 6,
                    precision: 0,
                    locale: 'pt-BR'
                  }"
                  v-model="
                    local.despesas[local.dia][tipo.key].despesaTransporteChegada
                  "
                />
              </td>
              <td>
                <h-money-input
                  class="right-input"
                  v-bind:properties="formatoColunasPlanilha"
                  v-bind:autocomplete="'new'"
                  v-bind:altlabel="'Transporte intramunicipal'"
                  v-bind:tip="
                    'Informe o valor aproximado gasto com transporte dentro da cidade por ' +
                      tipo.tip
                  "
                  v-bind:options="{
                    outputMask: '################',
                    length: 6,
                    precision: 0,
                    locale: 'pt-BR'
                  }"
                  v-model="
                    local.despesas[local.dia][tipo.key]
                      .despesatransporteRedondezas
                  "
                />
              </td>
              <td>
                <h-money-input
                  class="right-input"
                  v-bind:properties="formatoColunasPlanilha"
                  v-bind:autocomplete="'new'"
                  v-bind:altlabel="'Despesas com hospedagem'"
                  v-bind:tip="
                    'Informe o valor gasto com hospedagem ' + tipo.tip
                  "
                  v-bind:options="{
                    outputMask: '################',
                    length: 6,
                    precision: 0,
                    locale: 'pt-BR'
                  }"
                  v-model="
                    local.despesas[local.dia][tipo.key].despesaHospedagem
                  "
                />
              </td>
              <td>
                <h-money-input
                  class="right-input"
                  v-bind:properties="formatoColunasPlanilha"
                  v-bind:autocomplete="'new'"
                  v-bind:altlabel="'Despesas com alimento'"
                  v-bind:tip="
                    'Informe o valor gasto alimentação por ' + tipo.tip
                  "
                  v-bind:options="{
                    outputMask: '################',
                    length: 6,
                    precision: 0,
                    locale: 'pt-BR'
                  }"
                  v-model="local.despesas[local.dia][tipo.key].despesaAlimentos"
                />
              </td>
              <td>
                <h-money-input
                  class="right-input"
                  v-bind:properties="formatoColunasPlanilha"
                  v-bind:autocomplete="'new'"
                  v-bind:altlabel="'Outras despesas'"
                  v-bind:tip="
                    'Informe o valor gasto com outras despesas, como lembranças, aquisições de brindes, passeios pagos, etc; por ' +
                      tipo.tip
                  "
                  v-bind:options="{
                    outputMask: '################',
                    length: 6,
                    precision: 0,
                    locale: 'pt-BR'
                  }"
                  v-model="local.despesas[local.dia][tipo.key].despesaOutros"
                />
              </td>
              <td class="total totalColumn">
                {{
                  totalIndividualLinha(
                    local.despesas[local.dia][tipo.key]
                  ).toLocaleString("pt-BR", {
                    maximumFractionDigits: 0
                  })
                }}
              </td>
              <td class="total totalColumn">
                {{
                  totalGeralLinha(
                    local.despesas[local.dia][tipo.key]
                  ).toLocaleString("pt-BR", {
                    maximumFractionDigits: 0
                  })
                }}
              </td>
            </template>
          </tr>
          <tr>
            <td>
              TOTAIS
            </td>
            <td align="right">
              {{
                totalGeralGrupo(
                  "publico",
                  local.despesas[local.dia]
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td align="right">
              {{
                totalGeralGrupo(
                  "despesaTransporteChegada",
                  local.despesas[local.dia]
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td align="right">
              {{
                totalGeralGrupo(
                  "despesatransporteRedondezas",
                  local.despesas[local.dia]
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td align="right">
              {{
                totalGeralGrupo(
                  "despesaHospedagem",
                  local.despesas[local.dia]
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td align="right">
              {{
                totalGeralGrupo(
                  "despesaAlimentos",
                  local.despesas[local.dia]
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td align="right">
              {{
                totalGeralGrupo(
                  "despesaOutros",
                  local.despesas[local.dia]
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td align="right">
              {{
                totalIndividualLocalDiaPessoa(
                  local.despesas[local.dia]
                ).toLocaleString("pt-BR", {
                  maximumFractionDigits: 0
                })
              }}
            </td>
            <td align="right">
              {{
                totalGeralDiaLocal(local.despesas[local.dia]).toLocaleString(
                  "pt-BR",
                  {
                    maximumFractionDigits: 0
                  }
                )
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <v-progress-linear
      stream
      buffer-value="0"
      v-bind:value="percentualLotacao(local, local.despesas[local.dia])"
      :color="
        percentualLotacao(local, local.despesas[local.dia]) >= 100
          ? 'red'
          : 'primary'
      "
      height="48"
    >
      <template v-slot:default="{ value }">
        <strong>{{ value }}%</strong>
        <span class="mx-1">{{ "da capacidade total de: " }}</span>
        <strong>{{ parseInt(local.lotacao, 10) }}</strong>
      </template>
    </v-progress-linear>
  </section>
</template>

<script>
import HMoneyInput from "../custom/HMoneyInput";

export default {
  components: {
    HMoneyInput
  },
  props: {
    local: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    tipoPublico: [
      {
        label: "Local",
        key: "local",
        tip: "residentes próximas ao local do evento"
      },
      {
        label: "Excursionista",
        key: "excursionista",
        tip: "excursionistas (sem pernoite)"
      },
      {
        label: "Nacional",
        key: "nacional",
        tip:
          "brasileiros que passarão a temporada na região em função do evento"
      },
      {
        label: "Internacional",
        key: "internacional",
        tip:
          "estrangeiros que passarão a temporada na região em função do evento"
      }
    ],
    formatoGeralInput: {
      outlined: true
    },
    formatoColunasPlanilha: {
      outlined: false,
      dense: true
    }
  }),
  methods: {
    totalGeralGrupo: function(atributo, sheet) {
      // console.log( 'obtendo total geral grupo de ', atributo, sheet )
      if (!sheet) return 0;
      return Object.keys(sheet)
        .map(grupo => (sheet[grupo] && parseInt(sheet[grupo][atributo])) || 0)
        .filter(val => !isNaN(val))
        .reduce((acc, el) => acc + (typeof el !== "undefined" ? el - 0 : 0), 0);
    },
    parseValue: function(value) {
      if (typeof value == "undefined") return 0;
      return value - 0;
    },
    totalIndividualLocalDiaPessoa: function(plan) {
      return this.tipoPublico
        .map(tipo => {
          return this.totalIndividualLinha(
            (plan && plan[tipo.key] && plan[tipo.key]) || null
          );
        })
        .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0);
    },
    totalIndividualLocal(local) {
      return (
        (local.despesas &&
          local.despesas
            .map(plan => {
              return this.totalIndividualLocalDiaPessoa(plan);
            })
            .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0)) ||
        0
      );
    },
    totalGeralDiaLocal: function(plan) {
      return this.tipoPublico
        .map(tipo => {
          return this.totalGeralLinha(
            plan && plan[tipo.key] && plan[tipo.key || null]
          );
        })
        .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0);
    },
    metricaPublicoTotal() {
      return this.totalGeralPublicoProjeto();
    },
    metricaGOP() {
      return this.valorTotalFonteRecurso;
    },
    metricaInvestimentoPerCapita() {
      return this.metricaPublicoTotal() > 0
        ? this.metricaGOP() / this.metricaPublicoTotal()
        : 0;
    },
    metricaID() {
      return (
        this.metricaGOP() *
        parseFloat((this.project.segmento && this.project.segmento.f) || 0)
      );
    },
    metricaII() {
      return this.totalGeralProjeto();
    },
    metricaIT() {
      return this.metricaID() + this.metricaII();
    },
    metricaIAE() {
      return this.metricaGOP() > 0 ? this.metricaIT() / this.metricaGOP() : 0;
    },
    totalGeralProjeto: function() {
      return (
        (this.project.locais &&
          this.project.locais
            .map(local => {
              return local.despesas
                .map(plan => {
                  return this.totalGeralDiaLocal(plan);
                })
                .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0);
            })
            .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0)) ||
        0
      );
    },

    totalGeralPublicoProjeto: function() {
      return (
        (this.project.locais &&
          this.project.locais
            .map(local => {
              return this.totalPublicoLocal(local);
            })
            .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0)) ||
        0
      );
    },
    totalPublicoLocal: function(local) {
      if (!local) return 0;
      return local.despesas
        .map(plan => {
          return this.totalGeralGrupo("publico", plan);
        })
        .reduce((acc, valor) => (acc += isNaN(valor) ? 0 : valor), 0);
    },
    totalGeralLinha: function(linha) {
      if (!linha) return 0;
      return this.parseValue(linha.publico) * this.totalIndividualLinha(linha);
    },
    totalIndividualLinha: function(linha) {
      // console.log( 'obtendo totalINdividual linha de ', linha )
      if (!linha) return 0;
      return (
        this.parseValue(linha.despesaTransporteChegada) +
        this.parseValue(linha.despesatransporteRedondezas) +
        this.parseValue(linha.despesaHospedagem) +
        this.parseValue(linha.despesaAlimentos) +
        this.parseValue(linha.despesaOutros)
      );
    },
    percentualLotacao(local, localDia) {
      if (local.lotacao == 0) return 0;
      var perc = Math.trunc(
        (this.totalGeralGrupo("publico", localDia) / local.lotacao) * 100
      );
      return perc;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  width: 100%;

  .v-progress-linear {
    width: 100%;
    margin: 8px 0;
    position: relative;
    overflow-x: hidden !important;
    overflow-y: hidden;
  }

  > div {
    width: 100%;
    overflow-x: visible;
    overflow-y: hidden;
  }

  div {
    table {
      border-collapse: separate;
      border-spacing: 1px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-color: #f0f0f0;
      padding: 16px 0;

      thead {
        th {
          min-width: 130px;
          background-color: #f0f0f0;
          color: #ee837a;
          text-transform: uppercase;
          font-size: 0.8rem;

          &:first-child {
            border-bottom: none;
            border-left: none;
          }
        }
      }

      tbody {
        tr {
          font-size: 0.9rem;

          &:nth-last-of-type(1) {
            height: 48px;

            td {
              padding-right: 16px;
              font-weight: bold;
              border-bottom: solid 1px #d0d0d0;
            }
          }

          td {
            // overflow-x: hidden;
            // overflow-y: hidden;
            border-top: none;
            padding: 12px 6px 0;
            border-right: none;
            border-bottom: none;
            border-left: solid 1px #d0d0d0;
            position: relative;

            &:first-child {
              padding: 4px;
              width: 130px;
              border: none;
              border-bottom: solid 1px #d0d0d0;
              text-transform: uppercase;
              color: #573688;
              font-weight: bolder;
            }

            &:last-child {
              border-bottom: solid 1px #d0d0d0;
            }
          }
        }
      }
    }
  }
}

.totalColumn {
  background-color: #e9e9e9;
  font-weight: bold;
}

.total {
  padding-right: 16px;
  text-align: right;
}
</style>

<style scoped>
/deep/ input {
  text-align: right;
  padding: 4px 10px 0 !important;
}
</style>
